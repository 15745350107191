.container-8 {
    padding: 8px !important;
}

.container-h-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.container-v-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.container-12 {
    padding: 12px !important;
}

.container-h-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.container-v-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.container-16 {
    padding: 16px !important;
}

.container-h-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.container-v-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.container-22 {
    padding: 22px !important;
}

.container-h-22 {
    padding-left: 22px !important;
    padding-right: 22px !important;
}

.container-v-22 {
    padding-top: 22px !important;
    padding-bottom: 22px !important;
}

.container-30 {
    padding: 30px !important;
}

.container-h-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.container-v-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.container-40 {
    padding: 40px !important;
}

.container-h-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.container-v-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.container-50 {
    padding: 50px !important;
}

.container-h-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.container-v-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}



/* patching bootstrap's flex to bootstrap */
.flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.flex-col {
    display: flex !important;
    flex-direction: column !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.flex-stretch {
    flex: 1 !important
}

.width-stretch {
    width: 100% !important;
}

.height-stretch {
    height: 100% !important;
}