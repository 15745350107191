@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
    --bg-color: linear-gradient(90deg, rgb(220, 220, 220) 0% 15%, rgb(235, 235, 235) 50%, rgb(220, 220, 220) 85% 100%);
    --banner-overlay: linear-gradient(160deg, rgba(8, 8, 8, 0.3) 5%, rgba(255, 255, 255, 0.1) 49% 79%, rgba(8, 8, 8, 0.3) 95%);
}

html,
body {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw !important;
    font-family: 'Lato', sans-serif;
    background: var(--bg-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    overflow-x: hidden;
}

#root {
    flex: 1 !important;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(8, 8, 8, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(8, 8, 8, 0.7);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(8, 8, 8, 0.8);
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}


p {
    line-height: 1.8em;
}

a {
    text-decoration: none;
    color: rgba(8, 8, 8, 1);
}

.cw {
    position: absolute;
    bottom: -10px;
    right: 0px;
    font-size: 0.55em !important;
}

.contact-container {
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
}

.menu-item {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.9em;
    margin: 20px;
}

.menu-item.link {
    transition: all 0.2s ease-in-out;
    border-style: none none solid none;
    border-width: 1px;
    border-color: rgba(8, 8, 8, 0);
}

.menu-item.link:hover {
    cursor: pointer;
    border-color: rgba(8, 8, 8, 1);
}

.header {
    height: 200px !important;
    position: relative;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dots>div {
    margin-right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(245, 245, 245, 0.3);
    transition: all 1s ease-in-out;
    z-index: 2;
}

.dots>div.active {
    background-color: rgba(245, 245, 245, 0.8);
}

.banner {
    position: relative;
    height: 54vh;
    min-height: 575px;
    width: 100%;
}

.banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10%;
    width: 120%;
    height: 150%;
    background: rgba(28, 18, 8, 0.05);
    transform: rotate(-15deg);
}

.banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--banner-overlay);
    z-index: 2;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: 50% 90%;
    background-size: cover;
    opacity: 0;
    transition: all 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.slide:nth-child(1) {
    background-image: url('./assets/banner1.webp');
    z-index: 1;
}

.slide:nth-child(2) {
    background-image: url('./assets/banner2.webp');
}

.slide:nth-child(3) {
    background-image: url('./assets/banner3.webp');
}

.slide:nth-child(4) {
    background-image: url('./assets/banner4.webp');
}

.head-container {
    margin-top: -4vh;
    width: 30vw;
    z-index: 3;
}

.head {
    position: relative;
    background-image: url("./assets/head.jpg");
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    opacity: 0;
    overflow: hidden;
}

.slide-in {
    animation: slide-in 1.2s ease-in-out 0.1s forwards;
}

@keyframes slide-in {
    0% {
        margin-left: calc(30vw - 500px);
        opacity: 0;
    }

    70% {
        margin-left: calc(30vw - 300px);
    }

    100% {
        margin-left: calc(30vw - 300px);
        opacity: 1;
    }
}

.head::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--banner-overlay);
}


.logo-container {
    font-size: 2em;
    margin-right: 200px;
}

.logo-container h1:nth-child(1) {
    margin-right: 5px;
    margin-top: 0.5em;
    font-size: 2.2em;
}

.logo-container h1:nth-child(2) {
    font-weight: 500 !important;
}

.about-container {
    display: flex;
    flex-direction: row;
}

.info-container {
    width: 30%;
    margin-left: 100px;
    margin-top: 20px;
    margin-bottom: 40px
}

@media only screen and (max-width: 1600px) {
    .logo-container {
        margin-right: 100px;
    }

    .about-container {
        margin-bottom: 50px
    }

    .banner {
        min-height: 450px;
    }

    .info-container {
        width: 40%;
    }
}


@media only screen and (max-width: 999px) {
    .logo-container {
        margin-right: 50px;
    }

    .banner::before {
        content: '';
        position: absolute;
        top: 0;
        left: -50%;
        width: 200vw;
    }

    .head-container {
        margin-left: 120px;
    }

    .about-container {
        margin-bottom: 80px
    }

    .info-container {
        width: 40%;
        margin-left: 50px;
    }

}

@media only screen and (max-width: 768px) {

    .header {
        height: 150px !important;
    }

    .contact-container {
        width: 100vw !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .menu-item {
        margin: 8px;
    }

    .head {
        height: 180px;
        width: 180px;
    }

    @keyframes slide-in {
        0% {
            margin-left: calc(30vw - 360px);
            opacity: 0;
        }

        70% {
            margin-left: calc(30vw - 230px);
        }

        100% {
            margin-left: calc(30vw - 230px);
            opacity: 1;
        }
    }

    .logo-container {
        font-size: 1.1em;
        margin-right: 32px;
    }

    .logo-container h1:nth-child(1) {
        margin-right: 6px;
        margin-top: 0.60em;
        font-size: 2.1em;
    }

    .about-container {
        flex-direction: column;
        margin-top: -80px;
        margin-bottom: 20px;
    }

    .info-container {
        width: calc(100% - 100px);
        margin-left: 50px;
        margin-top: -40px;
    }

    .info-container p:nth-child(1),
    .info-container p:nth-child(2) {
        width: 100%;
        text-align: right;
    }

    .dots {
        justify-content: flex-end;
        padding-right: 22px;
    }

    .slide {
        background-position: 40% 50%;
    }

    .slide:nth-child(2),
    .slide:nth-child(4) {
        background-position: 20% 50%;
    }
}